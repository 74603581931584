import React from 'react'
import { Container, handleImgixUrl } from '@kogk/common'
import Img from 'gatsby-image'
import cn from 'classnames'
import { Link } from '@kogk/gatsby-theme-base'
import { AspectRatioBox } from '@islandsstofa/react'

interface Category {
  document: {
    body: {
      data: {
        heiti_flokks: string
      }
    }
  }
}

interface GreentechType {
  name: {
    text: string
  }
  excerpt: {
    html: string
  }
  about: {
    html: string
  }
  image: {
    alt: string
    url: string
  }
  website: {
    url: string
  }
  email: string
  partner: boolean
  categories: {
    category_main: Category
    category_sub: Category
  }
}

const GreentechPartner: React.FC<{ content: GreentechType }> = ({
  content
}) => {
  const thisIsMobile = 'd-block d-md-none'
  const thisIsDesktop = 'd-none d-md-block'

  if (!content) return null

  const Image: React.FC<{ containerClass?: string }> = ({
    containerClass = ''
  }) => (
    <div className={containerClass}>
      {content.image.url && (
        <AspectRatioBox
          ratio='widescreen'
          containerClass='modal-template__image-box'
        >
          <Img
            imgStyle={{ padding: '32px 64px', objectFit: 'contain' }}
            fluid={handleImgixUrl(content.image.url).gatsbyImageFluid()}
            alt={content.image.alt}
          />
        </AspectRatioBox>
      )}
    </div>
  )

  const Excerpt = () => (
    <div className='pl-0 col-md-5 pt-3 pt-md-5 pl-md-4'>
      <div>
        <h4 className='mb-0'>{content.name.text}</h4>
        <div
          className='parag'
          dangerouslySetInnerHTML={{ __html: content.excerpt.html }}
        />
      </div>
    </div>
  )

  const Content = () => (
    <div className='pt-3'>
      <div
        dangerouslySetInnerHTML={{ __html: content.about.html }}
        className='parag-small mb-3'
      />
      <div className='d-flex flex-column'>
        <Link className='modal-template__link' to={content.website.url}>
          {content.website.url}
        </Link>
        <Link className='modal-template__link' to={`mailto:${content.email}`}>
          {content.email}
        </Link>
      </div>
    </div>
  )

  return (
    <Container>
      {/* desktop */}
      <div className={cn(thisIsDesktop, 'w-100 modal-template__add-some-pad')}>
        <div className='d-flex'>
          <Image containerClass='col-7 p-0' />
          <Excerpt />
        </div>
        <Content />
      </div>

      {/* mobile */}
      <div className={cn(thisIsMobile, 'modal-template__add-some-pad')}>
        <Image containerClass='pt-4' />
        <Excerpt />
        <Content />
      </div>
    </Container>
  )
}

export default GreentechPartner
