import React from 'react'
import { Search as SearchBox } from '@root/src/components/site/SearchResults/SearchResults'

const Search = () => {
  return (
    <div className='d-flex align-items-center h-100 px-6'>
      <SearchBox isModal />
    </div>
  )
}

export default Search
