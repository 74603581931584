export const isEmailValid = (email: string) => {
  const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  return re.test(email)
}

/**
 *  this function returns 3 different orders
 *  depending on random number.
 *  This should be random enough
 */
export const pseudoRandom = <T>(
  arr: T[],
  customSort: (a: T, b: T) => number
) => {
  const MAX = 100
  const rndNum = Math.floor(Math.random() * Math.floor(MAX))
  if (rndNum < MAX / 4) {
    return arr.reverse()
  } else if (rndNum >= MAX / 4 && rndNum < MAX / 2) {
    return arr
  } else return arr.sort(customSort)
}

export const findAltPage = (
  submenu: { title: string; url: string }[],
  language: string
): { title: string; url: string } => {
  const altPage = submenu.find(page => page.title !== language)

  if (!altPage) {
    return language === 'en'
      ? { title: 'is', url: '/is' }
      : { title: 'en', url: '/' }
  }

  return altPage
}
