import React from 'react'
import { useGlobalData } from '@src/data/global-data'
import { findAltPage } from '@src/utils'
import { ButtonDep as Button } from '@islandsstofa/react'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Link } from 'gatsby'

type Props = {
  className?: string
}

const LanguageButton = ({ className = '' }: Props) => {
  const { langMenu } = useGlobalData()
  const { language } = useLanguage()

  const altPage = findAltPage(langMenu[0].subMenu, language)

  return (
    <Link to={altPage.url}>
      <Button
        className={className}
        type='secondary'
        label={altPage.title}
      ></Button>
    </Link>
  )
}

export default LanguageButton
