import React, { useContext } from 'react'
import { Container, Col, Sticky } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import { useGlobalData } from '@src/data/global-data'
import cn from 'classnames'
import Burger from '@cmp/site/NewBurger'
import { MobileMenuContext } from '@root/src/context/mobileMenuContext'
import { Icon, IconButton, useModal, MenuItemType } from '@islandsstofa/react'
import NavItem from '@cmp/site/NavItem'
import LanguageButton from '../LanguageButton/LanguageButton'

const Logo = () => {
  const { close: closeMobileMenu } = useContext(MobileMenuContext)
  return (
    <Link to='/' onClick={() => closeMobileMenu()}>
      <Icon containerClass='d-none d-lg-block logo mt-1' type='icelandicLogo' />
      <Icon containerClass='d-block d-lg-none logo' type='icelandicLogo' />
    </Link>
  )
}

const Navigation: React.FC<{
  items: MenuItemType[]
  containerClass: string
}> = ({ items, containerClass }) => {
  return (
    <div className={cn(containerClass)}>
      {items
        .filter(item => item)
        .map((item, idx) => (
          <NavItem key={idx} item={item} />
        ))}
    </div>
  )
}

const LanguageAndSearch = () => {
  const { executeModal } = useModal()
  const runModal = () => {
    executeModal([], 0, 'modal__search', 'Search')
  }

  return (
    <div className='d-flex'>
      <IconButton onClick={() => runModal()} type='search'></IconButton>
      <LanguageButton className='pl-2 pt-1' />
    </div>
  )
}

const NavBar = () => {
  const { mainMenu } = useGlobalData()
  const { isOpen } = useContext(MobileMenuContext)

  return (
    <Sticky initialHeight={88}>
      <nav
        className={cn('navbar', {
          'navbar--mobile-dropdown-open': isOpen
        })}
      >
        <Container>
          <div className='d-flex navbar__item-wrap align-items-center justify-content-between'>
            <div>
              <Logo />
            </div>
            <>
              <Navigation
                containerClass='w-100 justify-content-center d-none d-lg-flex'
                items={mainMenu}
              />
              <Col className='d-none d-lg-flex' col={1}>
                <LanguageAndSearch />
              </Col>
            </>

            <div className='d-flex d-lg-none align-items-center'>
              <LanguageButton className='pr-2 pt-1' />
              <Burger />
            </div>
          </div>
        </Container>
      </nav>
    </Sticky>
  )
}

export default NavBar
