import React from 'react'
import { tokenize, useSearch } from '@kogk/gatsby-plugin-js-search'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { getHighestRankedText } from '@islandsstofa/react'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'

const MatchedText = ({
  term,
  texts,
  className,
  ...rest
}: {
  term: string
  texts: any
  className?: string
}) => {
  if (!texts) {
    return null
  }

  const wordRegexes = tokenize(term).map(word => new RegExp(`(${word})`, 'gi'))
  const highestRankedText = getHighestRankedText(texts, wordRegexes)

  if (!highestRankedText) {
    const [first] = texts
    if (!first) {
      return null
    }
    return first
  }

  const __html = wordRegexes.reduce(
    (html, regex) =>
      html.replace(
        (regex: RegExp, match: string) => `<strong>${match}</strong>`
      ),
    highestRankedText
  )

  return (
    <p
      className={cn(className)}
      dangerouslySetInnerHTML={{
        __html
      }}
      {...rest}
    />
  )
}

const SearchResultsUi: React.FC<{ term: string; className?: string }> = ({
  term,
  className
}) => {
  const { language } = useLanguage()
  const search = useSearch(language)

  if (!search) {
    return null
  }

  return (
    <ol className={className}>
      {search(term).map((result: any) => (
        <Link key={result.url} to={result.url}>
          <li className='py-3 search-results-ui__result-box'>
            <h4 className='mb-2'>{result.title}</h4>
            <MatchedText
              className='m-0 parag'
              term={term}
              texts={result.text}
            />
          </li>
        </Link>
      ))}
    </ol>
  )
}

export default SearchResultsUi
